@import "src/styles/foundation";

.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 100.5rem;

    h2 {
        display: block;
        font-size: map-get($font-size-map, 4);
        font-weight: $font-weight-base;
        margin: 0;
        padding: map-get($spacer-map, 2) 0;

        @include media-breakpoint-up(md) {
            font-size: map-get($font-size-map, 5);
        }

        @include media-breakpoint-up(lg) {
            font-size: map-get($font-size-map, 6);
        }

        @include media-breakpoint-up(xl) {
            font-size: map-get($font-size-map, 7);
        }
    }

    .unit {
        font-size: 0.75em;
        vertical-align: text-top;
    }

    .header {
        flex-basis: 100%;
        position: relative;

        .logo {
            pointer-events: none;
            vertical-align: top;
            height: map-get($font-size-map, 8);

            @include media-breakpoint-up(md) {
                height: map-get($font-size-map, 9);
            }

            @include media-breakpoint-up(lg) {
                height: map-get($font-size-map, 10);
            }

            @include media-breakpoint-up(xl) {
                height: map-get($font-size-map, 11);
            }
        }

        .updated {
            font-size: map-get($font-size-map, 1);
            position: absolute;
            top: 0;
            right: 0;

            @include media-breakpoint-up(md) {
                font-size: map-get($font-size-map, 2);
            }

            @include media-breakpoint-up(lg) {
                font-size: map-get($font-size-map, 3);
            }

            @include media-breakpoint-up(xl) {
                font-size: map-get($font-size-map, 4);
            }
        }
    }

    .overview {
        flex-basis: 100%;
        display: grid;
        grid-gap: map-get($spacer-map, 6);
        grid-template-columns: auto 1fr;
        align-items: center;
        margin: map-get($spacer-map, 5) 0;

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: map-get($font-size-map, 8) * 3;

            @include media-breakpoint-up(md) {
                justify-content: flex-end;
                height: map-get($font-size-map, 9) * 3;
            }

            @include media-breakpoint-up(lg) {
                height: map-get($font-size-map, 10) * 3;
            }

            @include media-breakpoint-up(xl) {
                height: map-get($font-size-map, 11) * 3;
            }
        }

        .wrapper {
            .temperature {
                display: block;
                font-size: map-get($font-size-map, 8);
                line-height: normal;
                margin: 0;
                padding: 0;

                @include media-breakpoint-up(md) {
                    font-size: map-get($font-size-map, 9);
                }

                @include media-breakpoint-up(lg) {
                    font-size: map-get($font-size-map, 10);
                }

                @include media-breakpoint-up(xl) {
                    font-size: map-get($font-size-map, 11);
                }
            }

            .description {
                display: block;
                font-size: map-get($font-size-map, 2);
                margin: 0;
                padding: map-get($spacer-map, 1) 0;

                @include media-breakpoint-up(md) {
                    font-size: map-get($font-size-map, 3);
                }

                @include media-breakpoint-up(lg) {
                    font-size: map-get($font-size-map, 4);
                }

                @include media-breakpoint-up(xl) {
                    font-size: map-get($font-size-map, 5);
                }
            }
        }
    }

    .details {
        background: $t-white-a-24;
        border-radius: $border-radius-lg;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: map-get($spacer-map, 2) 0;
        margin: map-get($spacer-map, 4) 0;

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
        }

        .detail {
            flex-basis: calc(100% / 3);
            padding: map-get($spacer-map, 3);
            text-align: center;

            @include media-breakpoint-up(lg) {
                padding: map-get($spacer-map, 4) map-get($spacer-map, 2);
            }

            .value {
                display: block;
                font-size: map-get($font-size-map, 3);
                margin: 0;
                padding: map-get($spacer-map, 1) 0;

                @include media-breakpoint-up(md) {
                    font-size: map-get($font-size-map, 4);
                }

                @include media-breakpoint-up(lg) {
                    font-size: map-get($font-size-map, 5);
                }

                @include media-breakpoint-up(xl) {
                    font-size: map-get($font-size-map, 6);
                }
            }

            .label {
                display: block;
                font-size: map-get($font-size-map, 2);
                margin: 0;
                padding: map-get($spacer-map, 1) 0;

                @include media-breakpoint-up(md) {
                    font-size: map-get($font-size-map, 3);
                }

                @include media-breakpoint-up(lg) {
                    font-size: map-get($font-size-map, 4);
                }

                @include media-breakpoint-up(xl) {
                    font-size: map-get($font-size-map, 5);
                }
            }
        }
    }

    .forecast {
        flex-basis: 100%;
        margin: map-get($spacer-map, 4) 0;
        overflow: hidden;

        .slider {
            display: flex;
            overflow: scroll hidden;
            margin: map-get($spacer-map, 4) 0 0;
            padding: 0 0 map-get($spacer-map, 4);

            .label {
                display: block;
                font-size: map-get($font-size-map, 2);
                margin: 0;
                padding: map-get($spacer-map, 1) 0;
                text-align: center;

                @include media-breakpoint-up(md) {
                    font-size: map-get($font-size-map, 3);
                }

                @include media-breakpoint-up(lg) {
                    font-size: map-get($font-size-map, 4);
                }

                @include media-breakpoint-up(xl) {
                    font-size: map-get($font-size-map, 5);
                }
            }

            .day {
                background: $t-white-a-24;
                border-radius: $border-radius;
                flex-shrink: 0;
                flex-basis: map-get($font-size-map, 8) * 3;
                padding: map-get($spacer-map, 3);
                margin: 0 map-get($spacer-map, 2);

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                @include media-breakpoint-up(md) {
                    flex-basis: map-get($font-size-map, 9) * 3;
                }

                @include media-breakpoint-up(lg) {
                    flex-basis: map-get($font-size-map, 10) * 3;
                }

                @include media-breakpoint-up(xl) {
                    flex-basis: map-get($font-size-map, 11) * 3;
                }
            }
        }
    }
}
