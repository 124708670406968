// 600 Semi Bold (Demi Bold): https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
//@font-face {
//    font-family: Wotfard;
//    src: url(./media/fonts/wotfard/wotfard-semibold-webfont.woff2) format("woff2");
//    font-weight: 600;
//    font-style: normal;
//    font-display: fallback;
//}

// 500 Medium: https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
@font-face {
    font-family: Wotfard;
    src: url(./media/fonts/wotfard/wotfard-medium-webfont.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

// 400 Normal (Regular): https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
@font-face {
    font-family: Wotfard;
    src: url(./media/fonts/wotfard/wotfard-regular-webfont.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

// Variables and Functions (Custom and Bootstrap)

@import "src/styles/foundation";

// Bootstrap Reboot

@import "~bootstrap-scss/reboot";

// Custom Styles

html,
body {
    height: 100%;
}

body {
    background-attachment: fixed;
    background-image: url("./media/background.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 1rem;

    @media (min-width: 768px) {
        padding: 1.5rem;
    }
}

#root {
    display: flex;
    margin: 0 auto;
    max-width: calc(100vw - 2rem);

    @media (min-width: 768px) {
        align-items: center;
        min-height: calc(100vh - 3rem);
        max-width: calc(100vw - 3rem);
    }
}
