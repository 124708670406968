// Documents
// How you’ve been getting the Bootstrap grid all wrong—and how to fix it: https://medium.com/@erik_flowers/how-youve-been-getting-the-bootstrap-grid-all-wrong-and-how-to-fix-it-6d97b920aa40

// BOOTSTRAP VARIABLES
//
// Variables should follow the `$component-state-property-size` formula for consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// https://refactoringui.com/previews/building-your-color-palette/
// https://design.gitlab.com/product-foundations/colors/
// https://www.w3schools.com/colors/colors_picker.asp
// https://github.com/mjswensen/color-steps
// https://contrast-ratio.com

// IMPORTANT: Adjust colors by adjusting the saturation (hue should remain the same and lightness increments/decrements should be similar)
// hsl(hue, saturation, lightness)

// $ npx color-steps "rgb(27,39,51)" "black" 3
// $ npx color-steps "white" "rgb(27,39,51)" 7
// $ npx color-steps "white" "rgb(198,201,204)" 3
// $ npx color-steps "white" "rgb(227,228,230)" 9

$white: #ffffff !default;
$gray-10: #fcfcfd !default; // rgb(252,252,253)
$gray-50: #f1f2f2 !default; // rgb(241,242,242)
$gray-100: #e3e4e6 !default; // rgb(227,228,230)
$gray-200: #c6c9cc !default; // rgb(198,201,204)
$gray-300: #aaaeb3 !default; // rgb(170,174,179)
$gray-400: #8d9399 !default; // rgb(141,147,153)
$gray-500: #717880 !default; // rgb(113,120,128)
$gray-600: #545d66 !default; // rgb(84,93,102)
$gray-700: #38424c !default; // rgb(56,66,76)
$gray-800: #1b2733 !default; // rgb(27,39,51) | hsl(210, 47%, 20%)  // Blue Charcoal (https://www.htmlcsscolor.com/hex/1B2733).
$gray-900: #0e141a !default; // rgb(14,20,26)
$gray-950: #070a0d !default; // rgb(7,10,13)
$black: #000000 !default;

$t-white-a-02: rgba($white, 0.02) !default;
$t-white-a-04: rgba($white, 0.04) !default;
$t-white-a-06: rgba($white, 0.06) !default;
$t-white-a-08: rgba($white, 0.08) !default;
$t-white-a-24: rgba($white, 0.24) !default;

$t-black-a-02: rgba($black, 0.02) !default;
$t-black-a-04: rgba($black, 0.04) !default;
$t-black-a-06: rgba($black, 0.06) !default;
$t-black-a-08: rgba($black, 0.08) !default;
$t-black-a-24: rgba($black, 0.24) !default;

// $ npx color-steps "white" "rgb(33,166,155)" 9
// $ npx color-steps "rgb(33,166,155)" "black" 9

$cyan-50: #e9f6f5 !default; // rgb(233,246,245) | hsl(175, 5%, 96%)
$cyan-100: #d3edeb !default; // rgb(211,237,235) | hsl(175, 11%, 93%)
$cyan-200: #a6dbd7 !default; // rgb(166,219,215) | hsl(175, 24%, 86%)
$cyan-300: #7acac3 !default; // rgb(122,202,195) | hsl(175, 40%, 79%)
$cyan-400: #64c1b9 !default; // rgb(100,193,185) | hsl(175, 48%, 76%)
$cyan-500: #21a69b !default; // rgb(33,166,155) | hsl(175, 80%, 65%)  // Java (https://www.htmlcsscolor.com/hex/21A69B).
$cyan-600: #1a857c !default; // rgb(26,133,124) | hsl(175, 80%, 52%)
$cyan-700: #14645d !default; // rgb(20,100,93) | hsl(175, 80%, 39%)
$cyan-800: #0d423e !default; // rgb(13,66,62) | hsl(175, 80%, 26%)
$cyan-900: #07211f !default; // rgb(7,33,31) | hsl(175, 79%, 13%)
$cyan-950: #031110 !default; // rgb(3,17,16) | hsl(175, 82%, 7%)

$primary: $gray-800 !default;
$secondary: $cyan-500 !default;
//$success: $green !default;
//$info: $cyan !default;
//$warning: $yellow !default;
//$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these variables. Mostly focused on spacing.

$spacer-map: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.5rem,
    6: 2rem,
    7: 3rem,
    8: 4rem,
    9: 6rem,
    10: 8rem,
    11: 12rem,
    12: 16rem,
    13: 24rem,
    14: 32rem,
    15: 40rem,
    16: 48rem,
    xs: 0.25rem,
    sm: 0.5rem,
    md: 0.75rem,
    lg: 1rem,
    xl: 1.5rem,
) !default;

$spacer: 1rem !default; // Assumes the browser default, typically `16px`: map-get($spacer-map, 4)

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $primary !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: Wotfard, sans-serif !default;
// stylelint-enable value-keyword-case

$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;

$font-size-map: (
    1: 0.75rem,
    2: 0.875rem,
    3: 1rem,
    4: 1.125rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.875rem,
    8: 2.25rem,
    9: 3rem,
    10: 3.75rem,
    11: 4.5rem,
) !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
